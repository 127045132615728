@import "src/theme/variables.scss";


._color-selector {
	margin-bottom: 20px;
		
	._colors {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 10px;

		._color {
			border-radius: 100%;
			width: 20px;
			height: 20px;

			&._selected {
				border: 2px solid grey;
			}

			&:hover {
				cursor: pointer;
			}
		}
	}
}

._location {
	._address {
		font-size: 12px;
	}

	p {
		margin: 0;
	}
}

._employees {
	._employee-info {
		span {
			font-size: 12px;
		}

		
	}

	p {
		margin: 0;
	}
}

._custom-interval {
	margin-top: -15px;
	margin-bottom: 20px;
	margin-left: 5px;

	._interval-inputs {
		display: flex;
		align-items: center;
		flex-direction: row;
	
		p, input {
			margin-right: 10px;
		}
	
		input {
			background-color: #f1f1f1;
			border: none;
			padding: 6px 10px;
			height: 36px;
			font-size: 14px;
			color: #000;
			width: 50px;
			text-align: center;
			border-radius: 5px;
		}
	
		form-select {
			display: block;
			flex-grow: 10;
			margin: 0;
			margin-top: -3px;
	
			.ng-select-container {
				border: none !important;
				border-radius: 5px !important;
			}
		}
	}
}

._lessons-block{
	._lessons{
		._lesson{
			background-color: white;
			color: black;
			border: 1px solid #dedede;
			padding: 10px 15px;
			border-radius: 10px;
			box-shadow: 3px 3px 10px lightgrey;
			margin-bottom: 10px;
			font-size: 14px;
			cursor:pointer;

			&:hover{
				background-color: #f1f1f1;
			}
	
			p{
				margin:0px;
			}

			._description{
				
			}
		}
	}
	button{
		width:100%;
	}

	margin-bottom: 20px;
}

