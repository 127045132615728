@import "src/theme/variables.scss";

._communication-template-desktop-page {
	padding: 20px;

	._container {
		padding: 20px;
		background-color: white;
		box-shadow: $box-shadow-light;
		border-radius: $border-radius;
		margin-bottom: 20px;
		height: calc(100vh - 150px);
		display: flex;
		flex-direction: column;
		padding:0px;

		._communication-template-container{
			display: flex;
			flex-direction: column;
			overflow-y:auto;
			padding:20px;

			._editor-container{
				editor-component {
					display: block;
					flex-grow: 10;
					height:auto;
		
					.ql-container {
						height:auto;
					}
				}
			}
		}

		._buttons {
			width: 100%;
			margin-top: 0px;
			padding:20px;
			display: flex;
			justify-content: flex-end;
			border-top:1px solid #dedede;
		}
	}

}